<template>
  <sdCards
    headless
    class="start__header"
    v-if="(!userData?.user_personal_data?.first_name || !userData?.phone) && userData != null"
  >
    <div class="start__wrapper">
      <div class="start__inner">
        <sdHeading as="h5">{{ i18n.t('start.title.title') }}</sdHeading>
        <p v-html="i18n.t('start.title.description')"></p>
        <small>{{ i18n.t('start.title.data') }}</small>
      </div>
      <div class="start__inner">
        <router-link
          :to="{
            name: 'overview',
            query: {
              open: true,
            },
          }"
        >
          <sdButton type="dark" size="large">{{ i18n.t('start.title.button') }}</sdButton>
        </router-link>
      </div>
    </div>
  </sdCards>

  <a-carousel :autoplay="true" v-else-if="userData != null" class="start__header">
    <CardWrapper v-for="(banner, index) of banners" :key="index">
      <img :src="require(`@/static/img/banner/slider-${banner.img}`)" alt="" />
      <figcaption>
        <h2>{{ banner.title }}</h2>
        <p v-html="banner.text"></p>
        <a :href="links[banner.href_name][i18n.locale]" target="_blank">
          <sdButton type="primary" size="small">{{ banner.link_text }} </sdButton>
        </a>
      </figcaption>
    </CardWrapper>
  </a-carousel>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { UserOutlined } from '@ant-design/icons-vue';
import { computed, reactive, ref } from '@vue/runtime-core';
import { useStore } from 'vuex';
import { CardWrapper } from '../style';

const StartHeader = {
  name: 'StartHeader',
  components: {
    CardWrapper,
    UserOutlined,
  },
  setup() {
    const { state } = useStore();
    const i18n = reactive(useI18n());
    const userData = computed(() => state.profile.data);

    const links = ref({
      referral: {
        en: process.env.VUE_APP_API_EN_REFERAL_SHARING_URL + process.env.VUE_APP_FOR_PARTNERS,
        ru: process.env.VUE_APP_API_RU_REFERAL_SHARING_URL + process.env.VUE_APP_FOR_PARTNERS,
      },
      promo: {
        en: process.env.VUE_APP_API_EN_REFERAL_SHARING_URL + process.env.VUE_APP_PROMO_BUY_TOKENS,
        ru: process.env.VUE_APP_API_RU_REFERAL_SHARING_URL + process.env.VUE_APP_PROMO_BUY_TOKENS,
      },
    });

    const banners = computed(() => [
      {
        title: i18n.t('start.promoBuyToken.bannerTitleHead'),
        text: i18n.t('start.promoBuyToken.bannerHead'),
        img: '4.png',
        href_name: 'promo',
        link_text: i18n.t('start.promoBuyToken.more', 2),
      },
      {
        title: i18n.t('start.partnerProgramm.bannerTitleHead'),
        text: i18n.t('start.partnerProgramm.bannerHead'),
        img: '3.jpg',
        href_name: 'referral',
        link_text: i18n.t('start.partnerProgramm.more', 2),
      },
    ]);

    return {
      banners,
      userData,
      i18n,
      links,
    };
  },
};
export default StartHeader;
</script>
